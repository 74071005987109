<template>
  <el-dialog
    title="详情"
    :visible.sync="showDialog"
    width="1100px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
        disabled
        label-suffix=":"
      >
        <el-form-item label="仓库" prop="">
          <el-input v-model="form.depot_name" style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="商品名称" prop="">
          <el-input v-model="form.goods_name" style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="规格" prop="">
          <el-input v-model="form.goods_spec" style="width: 160px"></el-input>
        </el-form-item>
        <el-form-item label="换算" prop="">
          <el-input
            v-model="form.goods_convert"
            style="width: 160px"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-table
        ref="table"
        stripe
        :data="list"
        show-summary
        :summary-method="summaryMethod"
      >
        <!-- 序号 -->
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        >
          <template #default="{ row }">
            <div v-if="item.label == '单号'">
              <span class="underline" @click="handleCheckDetail(row)">
                {{ row[item.prop] }}
              </span>
            </div>
            <div v-else>
              {{ row[item.prop] }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" width="90px" label="操作">
          <template #default="{ row }">
            <el-button type="text" @click="handleShifang(row)">释放</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="dialog-footer">
        <el-button @click="showDialog = false">关闭</el-button>
      </div>
      <diaobo-detail ref="diaoboDetail" :is-edit="false"></diaobo-detail>
      <advance-detail ref="advanceDetail" :is-edit="false"></advance-detail>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import diaoboDetail from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'
  import advanceDetail from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  export default {
    name: 'Check',
    components: { diaoboDetail, advanceDetail },
    data() {
      return {
        loading: false,
        depot_id: null,
        id: null,
        showDialog: false,
        form: {},
        list: [],
        columns: [
          {
            label: '单号',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '状态',
            prop: 'status_text',
            width: '',
          },
          {
            label: '占用数量',
            prop: 'goods_num',
            width: '',
          },
        ],
        allTotal: 0,
      }
    },
    computed: {
      title() {
        return '标题'
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
        }
      },
      // list() {
      //   let _this = this
      //   this.$nextTick(() => {
      //     console.log('1')
      //     let html = _this.$refs.table.$refs.footerWrapper
      //       .querySelector('.el-table__footer')
      //       .querySelectorAll('td')[3]
      //       .querySelector('.cell')
      //     html.innerHTML =
      //       "<span style='color:#409eff;cursor:pointer;'>释放全部</span>"
      //     html.onclick = function () {
      //       _this.handleShifang()
      //     }
      //     console.log('2', html)
      //   })
      // },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await postAction('/depotAdmin/depot-stock/occupy-log', {
          goods_id: this.id,
          depot_id: this.depot_id,
        })
        console.log('data', data)
        this.form = data.info
        this.allTotal = data.heji
        this.list = data.list || []
        this.loading = false
        // /depotAdmin/depot-stock/summary-depot-car-detail
        // 库车统计 -- 详情
      },
      // 释放库存
      handleShifang(row) {
        if (row) {
          console.log('', row)
          postAction('/depotAdmin/depot-stock/clear-frozen', {
            ids: row.bill_id,
          }).then((r) => {
            this.fetchData()
            this.$message.success('释放成功')
          })
        } else {
          // console.log('释放全部')
        }
      },
      summaryMethod() {
        let sum = []
        sum[0] = '总计'
        sum[2] = this.allTotal
        return sum
      },
      handleCheckDetail(row) {
        console.log(row)
        // DB bill_type==2 DB 调拨单
        // XD bill_type==1 XD 销售订单
        if (row.bill_type == 1) {
          // this.$refs.advanceDetail.id = Number(row.bill_id)
          // this.$refs.advanceDetail.isshowDialog = true
          this.$refs['advanceDetail'].showDialog()
          this.$refs['advanceDetail'].orderStatus2 = row.status_text
          // this.$refs['advanceDetail'].orderStatus = row.bill_status_text
          this.$refs['advanceDetail'].id = row.bill_id
        } else if (row.bill_type == 2) {
          this.$refs.diaoboDetail.dialogVisible = true
          this.$refs.diaoboDetail.type = 1
          this.$refs.diaoboDetail.handlerlist({
            ...row,
            ...{ id: row.bill_id },
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped></style>
