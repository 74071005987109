var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "详情",
        visible: _vm.showDialog,
        width: "1100px",
        top: "15vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
                disabled: "",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "仓库", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    model: {
                      value: _vm.form.depot_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "depot_name", $$v)
                      },
                      expression: "form.depot_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    model: {
                      value: _vm.form.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_name", $$v)
                      },
                      expression: "form.goods_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规格", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    model: {
                      value: _vm.form.goods_spec,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_spec", $$v)
                      },
                      expression: "form.goods_spec",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "换算", prop: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    model: {
                      value: _vm.form.goods_convert,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_convert", $$v)
                      },
                      expression: "form.goods_convert",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "table",
              attrs: {
                stripe: "",
                data: _vm.list,
                "show-summary": "",
                "summary-method": _vm.summaryMethod,
              },
            },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: item.width,
                  align: "center",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var row = ref.row
                        return [
                          item.label == "单号"
                            ? _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "underline",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCheckDetail(row)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(row[item.prop]) + " ")]
                                ),
                              ])
                            : _c("div", [
                                _vm._v(" " + _vm._s(row[item.prop]) + " "),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
          _c("diaobo-detail", {
            ref: "diaoboDetail",
            attrs: { "is-edit": false },
          }),
          _c("advance-detail", {
            ref: "advanceDetail",
            attrs: { "is-edit": false },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }