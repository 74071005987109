<!--
 * @Author: 候怀烨
 * @Date: 2021-01-06 17:28:40
 * @LastEditTime: 2021-01-07 15:23:14
 * @LastEditors: Please set LastEditors
 * @Description: 实时库存
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotData\RealInventory\index.vue
-->
<template>
  <!-- NAME[epic=库管] 实时库存 -->
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="depot_id">
          <el-select
            v-model="form.depot_id"
            placeholder="请选择仓库"
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="class_id">
          <el-select
            v-model="form.class_id"
            placeholder="请选择品类"
            filterable
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="(item, index) in Category"
              :key="index"
              :label="item.class_name_txt"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="brand_id">
          <brand-select
            ref="brandSelect"
            :duoxuan="true"
            style="width: 160px"
            @brand-select-change="brandChange"
          ></brand-select>
          <!-- <el-select
            v-model="form.brand_id"
            placeholder="请选择品牌"
            style="width: 120px"
            clearable
          >
            <el-option
              v-for="(item, index) in Brand"
              :key="index"
              :label="item.brand_name"
              :value="item.id"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item prop="class_id">
          <el-select
            v-model="form.is_base"
            placeholder="请选择货值"
            style="width: 120px"
            clearable
          >
            <el-option label="成本价" value="1"></el-option>
            <el-option label="销售价" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="keyword">
          <el-input
            v-model="form.keyword"
            placeholder="商品名称，简拼"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="">
          <el-checkbox v-model="form.is_zero" :true-label="1" :false-label="0">
            显示零库存商品
          </el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <el-button @click="hanlderExport">导 出</el-button>
        </el-form-item>
        <div style="float: right">
          <div>
            <el-form-item label="货值合计:" prop="">
              {{ total_amount_new }}
            </el-form-item>
          </div>
          <!--          <div v-else>-->
          <!--            <el-form-item label="仓库总货值:" prop="">-->
          <!--              {{ total_amount }}-->
          <!--            </el-form-item>-->
          <!--            <el-form-item label="仓库成本:" prop="">-->
          <!--              {{ total_base_amount }}-->
          <!--            </el-form-item>-->
          <!--          </div>-->
        </div>
      </el-form>
    </el-row>
    <el-table
      stripe
      :data="tableData"
      :fit="true"
      row-key="id"
      @selection-change="selectChange"
      @sort-change="sortChange"
    >
      <!-- <el-table-column type="index"></el-table-column> -->
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in colemd"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        reserve-selection
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(list, index) in finallyColumns"
        :key="index"
        :label="list.label"
        :align="list.align"
        :prop="list.prop"
        :width="list.width"
        :sortable="list.sortable ? 'custom' : false"
      >
        <template slot="header">
          <div v-if="list.label == '可用库存'">
            <el-popover
              placement="bottom"
              title="说明"
              width="200"
              trigger="click"
              content="可用库存主要为避免下单量超出库存余量，
可用库存等于实际库存减占用库存，
数值仅供参考，下访销单会先记录占用库存，
调拨审核后会释放占用库存。"
            >
              <span slot="reference">
                {{ list.label }}
                <i class="el-icon-warning" />
              </span>
            </el-popover>
          </div>
          <div v-else-if="list.label == '货值'">
            <div v-if="form.is_base == 1">成本价货值</div>
            <div v-else>销售价货值</div>
          </div>
        </template>
        <template #default="{ row }">
          <div v-if="list.label == '占用库存'">
            <span
              style="text-decoration: underline; cursor: pointer"
              @click="handleDepotDetail(row)"
            >
              {{ row[list.prop] }}
            </span>
          </div>
          <div v-else-if="list.label == '实时库存'">
            <span
              style="
                text-decoration: underline;
                cursor: pointer;
                font-weight: bold;
              "
              @click="handleactuattime(row)"
            >
              {{ row[list.prop] }}
            </span>
          </div>
          <div v-else>{{ row[list.prop] }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" class="row-bg ww" justify="end">
      <div style="align-item: center">
        <el-button
          style="margin-right: 5px"
          type="primary"
          size="mini"
          @click="hanldeClearDepot"
        >
          <!-- 释放占用库存 -->
          修正占用库存
        </el-button>
        <!-- <el-button type="primary" size="mini" @click="hanldeClearDepot2">
          清空全部占用库存
        </el-button> -->
      </div>
      <el-pagination
        class="page"
        background
        style="width: 70%"
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <check ref="check"></check>
  </div>
</template>

<script>
  import Check from './components/check'
  import { postAction } from '@/api/Employee'
  import { exportDepot } from '@/api/goodsDetail'
  import BrandSelect from '@/baseComponents/brandSelect'
  import _ from 'lodash'
  import Template from '@/views/project/admin/systemManagement/adminAccount/components/template'
  export default {
    components: { BrandSelect, Check },
    data() {
      return {
        selectRows: [],
        total_base_amount: 0,
        total_amount: 0,
        form: {
          depot_id: '',
          class_id: '',
          brand_id: '',
          keyword: '',
          is_base: '2',
          pageNo: 1,
          pageSize: 10,
          sort: '',
          order: '',
        },
        total: 0,
        warehouse: [],
        Category: [],
        Brand: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          Category: '/baseAdmin/common/goods-class-list',
          Brand: '/baseAdmin/common/brand-list',
          list: '/depotAdmin/depot-stock/index',
        },
        tableData: [],
        checkList: [
          '仓库',
          '商品品类',
          '商品品牌',
          '商品编码',
          '商品名称',
          '规格',
          '货值',
          '实时库存',
          '占用库存',
          '可用库存',
        ],
        colemd: [
          {
            order: 0,
            label: '仓库',
            align: 'center',
            prop: 'depot_name',
            width: '',
            sortable: false,
          },
          {
            order: 1,
            label: '商品品类',
            align: 'center',
            prop: 'class_name',
            width: '',
            sortable: false,
          },
          {
            order: 2,
            label: '商品品牌',
            align: 'center',
            prop: 'brand_name',
            width: '',
            sortable: false,
          },
          {
            order: 3,
            label: '商品编码',
            align: 'center',
            prop: 'goods_id',
            width: '',
            sortable: false,
          },
          {
            order: 4,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
            sortable: false,
          },
          {
            order: 5,
            label: '规格',
            align: 'center',
            prop: 'goods_spec',
            width: '',
            sortable: false,
          },
          {
            order: 6,
            label: '货值',
            align: 'center',
            prop: 'balance_amount',
            width: '',
            sortable: false,
          },
          {
            order: 7,
            label: '实时库存',
            align: 'center',
            prop: 'balance_count',
            width: '160',
            sortable: true,
          },
          {
            order: 8,
            label: '占用库存',
            align: 'center',
            prop: 'frozen_count',
            width: '',
            sortable: false,
          },
          {
            order: 9,
            label: '可用库存',
            align: 'center',
            prop: 'usable_count',
            width: '',
            sortable: false,
          },
        ],
        total_amount_new: '',
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    mounted() {
      this.handlerwarehouse()
      this.handlerCategory()
      this.handlerBrand()
      this.handlerlist()
    },
    methods: {
      //排序
      sortChange({ column, prop, order }) {
        console.log('column, prop, order', column, prop, order)
        this.form.sort = 'ds.' + prop
        // 值 asc 小到大  和  desc  大到小]
        if (order == 'descending') {
          this.form.order = 'desc'
        } else if (order == 'ascending') {
          this.form.order = 'asc'
        } else {
          this.form.sort = ''
          this.form.order = ''
        }
        this.handlerlist()
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //品类
      handlerCategory() {
        postAction(this.url.Category, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品类')
            this.Category = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.$refs.brandSelect.resetForm()

        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.goods_list
            this.total = res.totalCount
            this.total_base_amount = res.data.total_base_amount
            this.total_amount = res.data.total_amount
            this.total_amount_new = res.data.total_amount_new
          })
          .catch((err) => {
            console.log(err)
          })
      },
      hanlderExport() {
        console.log('form', this.form)
        exportDepot(this.form)
          .then((res) => {
            console.log('导出')
            let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
            if ('download' in document.createElement('a')) {
              // 不是IE浏览器
              let url = window.URL.createObjectURL(blob)
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', '库存.xlsx')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link) // 下载完成移除元素
              window.URL.revokeObjectURL(url) // 释放掉blob对象
            } else {
              // IE 10+
              window.navigator.msSaveBlob(blob, fileName)
            }
            // let a = document.createElement('a')
            // let blob = new Blob([res], {
            //   type: 'application/vnd.ms-excel',
            // })
            // let objectUrl = window.URL.createObjectURL(blob)
            // a.setAttribute('href', objectUrl)
            // a.setAttribute('download', '库存.xlsx')
            // console.log(a)
            // a.click()
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      selectChange(val) {
        this.selectRows = val
      },
      // 修正占用库存
      hanldeClearDepot() {
        // if (this.selectRows.length == 0) {
        // this.$message.error('请勾选库存')
        // } else {
        // let ids = []
        // this.selectRows.forEach((i) => {
        //   ids.push(i.id)
        // })
        // ids = ids.join(',')
        // console.log('ids', ids)
        postAction('/depotAdmin/depot-stock/frozen-to-new').then((res) => {
          this.$message.success('修正占用库存成功')
          this.handlerlist()
        })
        // }
      },
      // 清空全部占用库存
      hanldeClearDepot2() {
        postAction('/depotAdmin/depot-stock/clear-frozen-all').then((res) => {
          this.$message.success('清空成功')
          this.handlerlist()
        })
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      handleDepotDetail(row) {
        console.log(row)
        this.$refs.check.id = row.goods_id
        this.$refs.check.depot_id = row.depot_id
        this.$refs.check.showDialog = true
      },
      //实时库存
      handleactuattime(row) {
        console.log(row)
        if (this.form.depot_id) {
          let url = this.$router.resolve(
            'inventoryChanges?goods_id=' +
              row.goods_id +
              '&depot_id=' +
              row.depot_id +
              '&goods_name=' +
              row.goods_name
          )
          console.log(url)

          window.open(url.href, '_blank')
        } else {
          this.$message({ type: 'warning', message: '请选择仓库' })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .ww {
    justify-content: space-between;
    margin-top: 20px;
    ::v-deep {
      .el-pagination {
        margin: 0 !important;
      }
    }
  }
</style>
